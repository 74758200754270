import { defineStore } from "pinia";

export const useSetupStore = defineStore("setup", {
  state: () => ({
    setups: {},
  }),
  actions: {
    setSetups(setups) {
      this.setups = setups;
    },
    setSetupsRaw(setupsRaw) {
      const setups = {};
      if (setupsRaw) {
        for (let i = 0; i < setupsRaw.length; i++) {
          const setup = setupsRaw[i];
          setups[setup.keyString] = setup.value;
        }
      }
      this.setups = setups;
    },
  },
  getters: {
    getSetups: (state) => state.setups,
  },
});
