import { defineStore } from "pinia";

export const useTabStore = defineStore("tab", {
  state: () => ({
    selectedTab: undefined,
    tabs: [],
  }),
  actions: {
    setSelectedTab(tab) {
      this.selectedTab = tab;
    },
    setTabs(tabs) {
      this.tabs = tabs;
    },
  },
  getters: {
    getTabs: (state) => state.tabs,
    getSelectedTab: (state) => state.selectedTab,
  },
});
