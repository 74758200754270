import { ref } from "vue";
const toastMessages = ref([]);
export function makeToast(msg: any, color: any) {
  let obj;
  if (typeof msg === "string") {
    if (!color) {
      color = "primary";
    }
    obj = {
      content: msg,
      color,
    };
  } else {
    obj = msg;
  }
  if (obj) {
    toastMessages.value.push(obj);
  } else {
    console.error("Toast object is null.", obj);
  }
}

export function useToast() {
  return { toastMessages };
}
