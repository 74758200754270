import { useVisitedUrlStore } from '~/stores/visitedUrl';
import {useTabStore} from "~/stores/tab";
import {useModuleStore} from "~/stores/module";
export default defineNuxtPlugin((nuxtApp: any) => {
    const urlStore = useVisitedUrlStore();
    const router = useRouter();
    const tabStore = useTabStore();
    const moduleStore = useModuleStore();
    if (localStorage.openedTabs) {
        const tabs = JSON.parse(localStorage.openedTabs);
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].moduleIndex = moduleStore.getNewId;
            tabs[i].moduleId = encodeURIComponent(tabs[i].path)
        }
        tabStore.setTabs(tabs);
    } else {
        tabStore.setTabs([]);
    }

    nuxtApp.$router.beforeEach((to: any, from: any, next: any) => {
        if (to.path !== "/login" && to.path !== "/login/") {
            let found = false;
            const tabs = tabStore.getTabs;
            for (let i = 0; i < tabs.length; i++) {
                if (
                    tabs[i].path === to.path ||
                    tabs[i].path + "/" === to.path
                ) {
                    found = true;
                    tabs[i].fullPath = to.fullPath;
                    tabStore.setSelectedTab(tabs[i]);
                }
            }
            if (!found) {
                const routes = router.getRoutes();
                let foundRoute;
                let name;
                let icon;
                for (let i = 0; i < routes.length; i++) {
                    if (
                        routes[i].path === to.path ||
                        routes[i].path + "/" === to.path
                    ) {
                        name = routes[i].name;
                        foundRoute = to;
                        if (routes[i].meta) {
                            icon = routes[i].meta.icon;
                        }
                        break;
                    }
                }
                let closable = true;
                let nameIsNotI18n = false;
                if (foundRoute && foundRoute.path === "/") {
                    name = "page.name.dashboard";
                    icon = "cil-chart";
                    closable = false;
                } else if (foundRoute && foundRoute.meta && foundRoute.meta.nameAlias) {
                    name = foundRoute.meta.nameAlias;
                } else {
                    nameIsNotI18n = true;
                    name = toPascalCase(name);
                }
                if (!icon) {
                    icon = "cib-circle";
                }
                const newTab = {
                    moduleIndex: moduleStore.getNewId,
                    moduleId: encodeURIComponent(to.path),
                    path: to.path,
                    fullPath: to.fullPath,
                    name,
                    nameIsNotI18n,
                    icon,
                    closable,
                };
                tabs.push(newTab);
                let foundDashboard = false;
                for (let i = 0; i < tabs.length; i++) {
                    if (tabs[i].path === "/") {
                        foundDashboard = true;
                    }
                }
                if (!foundDashboard) {
                    tabs.unshift({
                        path: "/",
                        fullPath: "/",
                        name: "page.name.dashboard",
                        icon: "cil-chart",
                        closable: false,
                    });
                }
                tabStore.setTabs(tabs);
                tabStore.setSelectedTab(newTab);
                localStorage.openedTabs = JSON.stringify(tabs);
            }
        }
        urlStore.addUrl(to.fullPath); // 경로를 Pinia 스토어에 추가. 오류 발생시 추적용.

        let routes;
        if (localStorage.visitedRoutes) {
            routes = JSON.parse(localStorage.visitedRoutes);
        } else {
            routes = [];
        }
        routes.push({
            fullPath: to.fullPath,
            path: to.path,
            hash: to.hash,
            href: to.href,
            meta: to.meta,
            params: to.params,
            query: to.query,
        });
        if (routes.length > 20) {
            routes.splice(0, routes.length - 20);
        }
        localStorage.visitedRoutes = JSON.stringify(routes);
        next(); // 라우팅 계속 진행
    });
});