let isInitializing = true;
export function useInitializing() {
  const setInitialized = () => {
    isInitializing = false;
  };

  const isInitialized = () => !isInitializing;

  return {
    setInitialized,
    isInitialized,
  };
}
