import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", {
  state: () => ({
    loading: null,
  }),
  actions: {
    startLoading() {
      setTimeout(() => {
        this.loading = true;
      });
    },
    stopLoading() {
      setTimeout(() => {
        this.loading = false;
      });
    },
  },
  getters: {
    isLoading: (state) => state.loading,
  },
});
