import moment from "moment";
import { useNuxtApp } from "#app";
import { useI18n } from "vue-i18n";

/**
 * 문자열을 치환하는 함수.
 *
 * @param input 입력값
 * @param searchStr 찾을 문자열
 * @param replaceStr 대체될 문자열
 */
export function replaceAll(
  input: string,
  searchStr: string,
  replaceStr: string,
) {
  if (!input) {
    return input;
  }
  return input.split(searchStr).join(replaceStr);
}

const entityMap: any = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "`": "&#x60;",
  "=": "&#x3D;",
};

/**
 * 태그를 제거하는 함수.
 *
 * @param input 입력값
 */
export function stripTags(input: string) {
  if (!input) {
    return input;
  }
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.body.textContent || "";
}

/**
 * 문장을 잘라서 반환.
 *
 * @param input 입력값
 * @param len 길이
 */
export function getShortContents(input: string, len: number) {
  if (!input) {
    return input;
  }
  if (len > input.length) {
    return input;
  }
  return input.substring(0, len) + "...";
}

/**
 * Camel case로 변경.(첫글자 소문자로 시작)
 *
 * @param input 입력 문자열
 * @return 변형된 문자열
 */
export function toCamelCase(input: any) {
  if (!input) {
    return input;
  }
  return input
    .replace(/-/g, "_") // 하이픈을 언더스코어로 변경
    .replace(/(_\w)/g, function (m: string) {
      return m[1].toUpperCase(); // 언더스코어 뒤 문자를 대문자로 변환
    })
    .replace(/^[A-Z]/, function (m: string) {
      return m.toLowerCase(); // 맨 앞글자가 대문자면 소문자로 변환
    });
}

/**
 * Snake case로 변경.(첫글자 소문자로 시작)
 *
 * @param input 입력 문자열
 * @return 변형된 문자열
 */
export function toSnakeCase(input: any) {
  if (!input) {
    return input;
  }
  return (
    input
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .replace(/\.?([A-Z]+)/g, function (x: any, y: any) {
        return "_" + y.toLowerCase();
      })
      .replace(/^_/, "")
  );
}

/**
 * Kebab case로 변경.(첫글자 소문자로 시작)
 *
 * @param input 입력 문자열
 * @return 변형된 문자열
 */
export function toKebabCase(input: any) {
  if (!input) {
    return input;
  }
  return (
    input
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .replace(/\.?([A-Z]+)/g, function (x: any, y: any) {
        return "-" + y.toLowerCase();
      })
      .replace(/^-/, "")
  );
}

/**
 * Pascal case로 변경.(첫글자 대문자로 시작)
 *
 * @param input 입력 문자열
 * @return 변형된 문자열
 */
export function toPascalCase(input: any) {
  if (!input) {
    return input;
  }
  return input
    .replace(/-/g, "_") // 하이픈을 언더스코어로 바꿉니다.
    .replace(/(_\w)/g, function (m: string) {
      return m[1].toUpperCase(); // 언더스코어 다음 문자를 대문자로 변환합니다.
    })
    .replace(/^\w/, function (m: string) {
      return m.toUpperCase(); // 첫 번째 문자도 대문자로 변환합니다.
    });
}

/**
 * 얼마나 시간이 지났는지 표현해주는 함수.
 *
 * @param totalSeconds 전체 초
 */
export function getDurationInString(totalSeconds: any) {
  if (!totalSeconds) {
    return "00:00";
  }
  let seconds: string = (totalSeconds % 60).toString();
  let minutes: string = (Math.floor(totalSeconds / 60) % 60).toString();
  if (parseInt(seconds) < 10) {
    seconds = "0" + seconds;
  }
  if (parseInt(minutes) < 10) {
    minutes = "0" + minutes;
  }

  if (totalSeconds < 3600) {
    return minutes + ":" + seconds;
  }
  const hours = Math.floor(totalSeconds / 3600);
  return hours + ":" + minutes + ":" + seconds;
}

/**
 * 임의의 문자열을 반환하는 함수.
 *
 * @param length 길이
 */
export function randomString(length: number) {
  if (!length) {
    length = 10;
  }
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * HTML 이스케이프 함수.
 *
 * @param input 입력값
 */
export function escapeHtml(input: string) {
  if (!input) {
    return input;
  }
  return input.replace(/[&<>"'`=]/g, function (s: any) {
    return entityMap[s];
  });
}

/**
 * 문자열의 바이트수를 가져오는 함수.
 *
 * @param input 입력값
 */
export function getBytesCount(input: string) {
  if (!input) {
    return input;
  }
  const strLen = input.length;
  let rbyte = 0;
  let oneChar = "";

  for (let i = 0; i < strLen; i++) {
    oneChar = input.charAt(i);
    if (escape(oneChar).length > 4) {
      rbyte += 2; // 한글2Byte
    } else {
      rbyte++; // 영문 등 나머지 1Byte
    }
  }
  return rbyte;
}

/**
 * 타임스탬프를 일시로 보여주는 함수.
 *
 * @param timestamp 값(타임스탬프)
 */
export function getDatetimeString(timestamp: any) {
  if (!timestamp) {
    return "";
  }
  if (isNaN(timestamp)) {
    return timestamp;
  }
  const myNuxtApp: any = useNuxtApp();
  return moment(parseInt(timestamp)).format(
    myNuxtApp.vueApp.$i18n.global.t("common.format.datetime"),
  );
}

/**
 * 타임스탬프를 날짜로 보여주는 함수.
 *
 * @param timestamp 타임스탬프
 */
export function getDateString(timestamp: any) {
  if (!timestamp) {
    return "";
  }
  if (isNaN(timestamp)) {
    return timestamp;
  }
  const myNuxtApp: any = useNuxtApp();
  return moment(parseInt(timestamp)).format(
    myNuxtApp.vueApp.$i18n.global.t("common.format.date"),
  );
}

/**
 * 타임스탬프를 시간으로 보여주는 함수.
 *
 * @param timestamp 타임스탬프
 */
export function getTimeString(timestamp: any) {
  if (!timestamp) {
    return "";
  }
  if (isNaN(timestamp)) {
    return timestamp;
  }
  const myNuxtApp: any = useNuxtApp();
  return moment(parseInt(timestamp)).format(
    myNuxtApp.vueApp.$i18n.global.t("common.format.time"),
  );
}

/**
 * 경로를 잘라서 배열로 반환하는 함수.
 *
 * @param path 경로
 */
export function splitPath(path: string) {
  if (!path) {
    return [];
  }
  // path를 '/'로 나누고, 빈 문자열을 제거한 배열 반환
  return path.split("/").filter((segment) => segment.length > 0);
}

// Moment.js와 date-fns 간의 포맷 매핑 테이블
const momentToDateFnsMap: any = {
  // 기본 포맷 문자열
  YYYY: "yyyy",
  YY: "yy",
  MMMM: "MMMM",
  MMM: "MMM",
  MM: "MM",
  M: "M",
  DD: "dd",
  D: "d",
  dddd: "EEEE",
  ddd: "EEE",
  A: "a",
  a: "a",
  HH: "HH",
  H: "H",
  hh: "hh",
  h: "h",
  mm: "mm",
  m: "m",
  ss: "ss",
  s: "s",
  SSS: "SSS",
  Z: "XXX",
  ZZ: "xx",
};

// moment포맷을 date_fns 포맷으로 변환해주는 함수
export function convertMomentToDateFnsFormat(momentFormat: string) {
  if (!momentFormat) {
    return "";
  }
  return momentFormat.replace(
    /\b(YYYY|YY|MMMM|MMM|MM|M|DD|D|dddd|ddd|A|a|HH|H|hh|h|mm|m|ss|s|SSS|Z|ZZ)\b/g,
    (match) => momentToDateFnsMap[match] || match,
  );
}

export function getFileSizeInString(filesize: any) {
  if (!filesize) {
    return "";
  }
  let unit = "B";
  if (filesize >= 1024) {
    unit = "KB";
    filesize = filesize / 1024;
  }
  if (filesize >= 1024) {
    unit = "MB";
    filesize = filesize / 1024;
  }
  if (filesize >= 1024) {
    unit = "GB";
    filesize = filesize / 1024;
  }
  if (filesize >= 1024) {
    unit = "TB";
    filesize = filesize / 1024;
  }
  return filesize.toFixed(2) + unit;
}

export function getI18nArray(prefix: any, arr: any, seperator: any) {
  const { t } = useI18n();
  if (!arr || arr.length === 0) {
    return "";
  }
  let ret = "";
  for (let i = 0; i < arr.length; i++) {
    if (seperator && i > 0) {
      ret += seperator + " ";
    }
    ret += t((prefix || "") + toSnakeCase(arr[i]));
  }
  return ret;
}
