import { createPinia } from "pinia";
import { loadCharsts, makeMenuNames } from "~/composables/i18n";
import { useMenusStore } from "~/stores/menu";
import { useSetupStore } from "~/stores/setup";
import { useLocaleStore } from "~/stores/locale";
let initLoaded = false;
async function init(nuxtApp: any) {
    nuxtApp.vueApp.use(createPinia());
    nuxtApp._isNuxtPageUsed = true;
    nuxtApp._isNuxtLayoutUsed = true;
    let exception;
    if (!initLoaded) {
        try {
            let url = "/common/public/init?";
            if (localStorage.localeRevision) {
                url += "localeRevision=" + localStorage.localeRevision + "&";
            }
            let locale;
            if (localStorage.locale) {
                locale = JSON.parse(localStorage.locale);
                if (localStorage["charstRevision_" + locale.id]) {
                    url +=
                        "charstRevision=" + localStorage["charstRevision_" + locale.id] + "&";
                }
            }
            if (localStorage.setupRevision) {
                url += "setupRevision=" + localStorage.setupRevision + "&";
            }
            const response: any = await useCustomFetch(url, { hideLoading: true });
            if (!(response && response.error && response.error.value)) {
                const data = response.data.value.data;
                let locales = data.locales;
                if (locales) {
                    localStorage.locales = JSON.stringify(locales);
                } else {
                    locales = JSON.parse(localStorage.locales);
                }
                let setups = data.setups;
                if (setups) {
                    localStorage.setups = JSON.stringify(setups);
                } else {
                    setups = JSON.parse(localStorage.setups);
                }
                const setupStore = useSetupStore();
                setupStore.setSetupsRaw(setups);
                let locale = data.locale;
                if (locale) {
                    localStorage.locale = JSON.stringify(locale);
                } else {
                    locale = JSON.parse(localStorage.locale);
                }
                const localeStore = useLocaleStore();
                localeStore.setLocale(locale);
                localeStore.setLocales(locales);
                const charsts = data.charsts;
                if (charsts) {
                    localStorage["charsts_" + locale.id] = JSON.stringify(
                        charstsToMap(charsts),
                    );
                }
                loadCharsts();
                localStorage.localeRevision = data.localeRevision;
                localStorage["charstRevision_" + locale.id] =
                    data.charstRevision;
                localStorage.setupRevision = data.setupRevision;
                const menus = data.menus;
                makeMenuNames(menus);
                const menusStore = useMenusStore();
                await menusStore.setMenus(menus);
            } else {
                loadCharsts();
                exception = response.error.value;
            }
        } catch (e) {
            loadCharsts();
            exception = e;
            const { setInitialized } = useInitializing();
            setInitialized();
        } finally {
            initLoaded = true;
            const router = useRouter();
            await router.isReady();
        }
        if (exception) {
            throw exception;
        }
    }
}
export default defineNuxtPlugin(async (nuxtApp) => {
    await init(nuxtApp);
});
