import { useCookie } from "#app";

const token = useCookie("auth_token");
const refreshToken = useCookie("refresh_token");
export const useAuth = () => {
  const setTokens = (newToken, newRefreshToken) => {
    token.value = newToken;
    refreshToken.value = newRefreshToken;
  };

  const clearTokens = () => {
    token.value = null;
    refreshToken.value = null;
  };

  const isAuthenticated = () => !!token.value;

  return {
    token,
    refreshToken,
    setTokens,
    clearTokens,
    isAuthenticated,
  };
};
