import { defineStore } from "pinia";

export const useVisitedUrlStore = defineStore("visitedUrlStore", {
  state: () => ({
    visitedUrls: [],
  }),
  actions: {
    addUrl(url) {
      this.visitedUrls.push(url);
    },
  },
});
