import revive_payload_client_4sVQNw7RlN from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/jenkins_home/workspace/waresync-admin-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/jenkins_home/workspace/waresync-admin-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import errorHandler_x1Y4n5NP4w from "/var/jenkins_home/workspace/waresync-admin-frontend/plugins/errorHandler.ts";
import init_client_lHDJZN4lq2 from "/var/jenkins_home/workspace/waresync-admin-frontend/plugins/init.client.ts";
import trackRoutes_HQQsfVuJFF from "/var/jenkins_home/workspace/waresync-admin-frontend/plugins/trackRoutes.ts";
import coreui_client_4JxNmL8elW from "/var/jenkins_home/workspace/waresync-admin-frontend/plugins/coreui.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  errorHandler_x1Y4n5NP4w,
  init_client_lHDJZN4lq2,
  trackRoutes_HQQsfVuJFF,
  coreui_client_4JxNmL8elW
]