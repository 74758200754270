import { default as _404hJLphgzEFlMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/404.vue?macro=true";
import { default as _500XU2TdR65KLMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/500.vue?macro=true";
import { default as chart8UHl56NytkMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/chart.vue?macro=true";
import { default as errorkwUelXN7rNMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/error.vue?macro=true";
import { default as indexMu5UvduOQIMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/index.vue?macro=true";
import { default as loginQqrrea13zGMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/login.vue?macro=true";
import { default as registrationKn44lR4ZPSMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/registration.vue?macro=true";
import { default as testcsotM1zCIUMeta } from "/var/jenkins_home/workspace/waresync-admin-frontend/pages/test.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404hJLphgzEFlMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500XU2TdR65KLMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "chart",
    path: "/chart",
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/chart.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: errorkwUelXN7rNMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexMu5UvduOQIMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginQqrrea13zGMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationKn44lR4ZPSMeta || {},
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/jenkins_home/workspace/waresync-admin-frontend/pages/test.vue").then(m => m.default || m)
  }
]