import { defineStore } from "pinia";

const moduleComponents = {};

async function makeMenusToRoutes(menus, parentPath) {
  const ret = [];
  const config = useRuntimeConfig();
  if (!menus) {
    return ret;
  }
  if (!parentPath) {
    parentPath = "";
  }
  parentPath += "/";
  for (let i = 0; i < menus.length; i++) {
    const route = {};
    const menu = menus[i];
    route.path = parentPath + menu.alias;
    route.name = menu.name;
    if (!route.meta) {
      route.meta = {};
    }
    route.meta.nameAlias =
      "menu.nm." + config.public.siteAlias + "." + menu.alias;
    route.meta.isDynamic = true;
    route.meta.menu = menu;
    route.meta.icon = menu.icon;
    if (menu.module) {
      const moduleName = menu.module.typeName;
      moduleComponents[menu.module.typeName] = import(
        `~/components/modules/${moduleName}.vue`
      );
      route.component = () => moduleComponents[menus[i].module.typeName];
    }
    if (menu.children) {
      const children = menu.children;
      for (let j = 0; j < children.length; j++) {
        route.children = await makeMenusToRoutes(
          children,
          parentPath + menu.alias,
        );
      }
    }
    ret.push(route);
  }
  return ret;
}

function removeDynamicRoutes(routes, router) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (!route || !route.meta || !route.meta.isDynamic) {
      continue;
    }
    router.removeRoute(route.name);
  }
}

function addDynamicRoutes(routes, router) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (route.children && route.children.length > 0) {
      addDynamicRoutes(route.children, router);
    }
    if (route.component) {
      router.addRoute(routes[i]);
    }
  }
}

export const useMenusStore = defineStore("menus", {
  state: () => ({
    menus: [],
  }),
  actions: {
    async setMenus(menus) {
      const router = useRouter();
      const routes = router.getRoutes();
      removeDynamicRoutes(routes, router);
      addDynamicRoutes(await makeMenusToRoutes(menus), router);
      this.menus = menus;
    },
    getMenuByAlias(alias, menus) {
      if (!menus) {
        menus = this.menus;
      }
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].alias === alias) {
          return menus[i];
        }
        if (menus[i].children) {
          const foundMenu = this.getMenuByAlias(alias, menus[i].children);
          if (foundMenu) {
            return foundMenu;
          }
        }
      }
    },
  },
  getters: {
    getMenus: (state) => state.menus,
  },
});
